<script>
export default {
  data() {
    return {
      dropdownOpen: false,
      vmodel: "",
      filterValueItemList: "",
    };
  },

  watch: {
    resetValue: {
      handler(newValue) {
        this.vmodel = newValue
      },
      immediate: true,
    },
    vmodel: {
      handler(newValue) {
        this.$emit("update:modelValue", newValue);
      },
      immediate: true,
    },
    listItems: {
      clearField() {
       this.vmodel = '';
      },
    },
    setDisabled: {
      setDisabled(newValue) {
       this.setDisabled = newValue;
      },
    },
    isInvalid: {
      setisInvalid(newValue) {
       this.isInvalid = newValue;
      },
    },
  },

  props: {
    resetValue: String,

    listItems: {
      type: Array,
      required: true,
    },

    itemTemplate: {
      type: Function,
      default: (item) => item.name,
    },

    itemName: String,

    dropdownId: {
      type: String,
      required: true,
    },
    required: Boolean,
    setLabel: String,
    setPlaceholder: String,
    allProducts: Boolean,
    setDisabled: Boolean,
    isInvalid: Boolean,
    allText: String,
  },

  computed: {
    filteredItemList() {
      if (this.filterValueItemList.trim() == "") {
        return this.listItems;
      }

      const searchTerm = this.filterValueItemList.trim().toLowerCase();

      return this.listItems.filter((data) =>
        data.name.toLowerCase().includes(searchTerm)
      );
    },
  },

  methods: {
    statusList(item) {
      const formattedItem = this.getFormattedItem(item);
      document.getElementById(this.dropdownId).value = formattedItem;
      this.vmodel = formattedItem;
    },

    closeDropdown(event) {
      const dropdown = this.$el.querySelector(`#${this.dropdownId}`);

      if (dropdown && !dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    getFormattedItem(item) {
      return this.itemTemplate(item);
    },

    onItemClick(item) {
      this.dropdownOpen = !this.dropdownOpen;
      this.statusList(item);
      this.$emit("item-clicked", item);
    },
    handleFocus() {
      // Adiciona um método de foco ao componente
      this.$emit('focus'); // Emite um evento de foco quando o campo recebe foco
    },
    focus() {
      // Define o foco no campo de entrada
      this.$refs.inputField.focus();
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdown);
  },
};
</script>

<template>
  <div class="dropdown-content">
    <label class="labelTitle" v-if="setLabel" :for="`id${dropdownId}`">{{
      setLabel
    }}</label>
    <div
      :class="[dropdownOpen == true ? 'open' : '', {'is-invalid': isInvalid}]"
      class="dropdownButton"
      :id="dropdownId"
    >
      <input
        @click="dropdownOpen = !dropdownOpen"
        type="text"
        @input="$emit('update:modelValue', $event.target.value)"
        v-model="vmodel"
        :id="`id${dropdownId}`"
        :Placeholder="setPlaceholder"
        :name="dropdownId"
        ref="inputField"
        :disabled="setDisabled"
        @blur="$emit('blurActive', false)"
        @focus="handleFocus"
        :required="required"
        readonly
      />
      <Transition>
        <ul v-if="dropdownOpen" class="dropdown-menu">
          <input
            v-if="listItems?.length > 5"
            type="text"
            v-model="filterValueItemList"
          />

          <li
            v-if="allProducts"
            @click="
              dropdownOpen = !dropdownOpen;
              statusList({name: allText ?? 'Todos os produtos', id: null });
              $emit('item-clicked', {
                index: null,
                id: null,
                name: allText ?? 'Todos os produtos',
              });
            "
          >
            {{ allText ?? 'Todos os produtos' }}
          </li>
          <li
            v-for="(item, index) in filteredItemList"
            :key="index"
            @click="
              onItemClick(item);
            "
            v-html="getFormattedItem(item)"
          ></li>
        </ul>
      </Transition>
    </div>
  </div>
</template>

<style scoped>
.dropdown-menu {
  max-height: 250px !important;
  overflow-y: scroll !important;
}

.is-invalid::before {
  content: '';
  display: none;
}
</style>
