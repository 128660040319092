<script>
import { badgeTypes } from '@/js/badges'
export default {
    props: {
        badges: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            allBadges: badgeTypes,
            processedBadges: undefined,
        };
    },
    methods: {
        getBodyClass(badge) {
            let body = `badge-body-${this.variant}`;

            if (!badge.isUnlocked) {
                body += ' greyscale'
            }

            return body;
        }
    },
    created() {
        this.processedBadges = this.badges?.map((badge) => {
            const badgeInfo = this.allBadges.find((type) => type.type === badge.name);
            return {
                ...badgeInfo,
                dateUnlocked: badge.dateCreated,
                isUnlocked: true
            };
        }).sort((a, b) => a.dateUnlocked > b.dateUnlocked ? 1 : -1);

        const lockedBadges = this.allBadges.filter((badge) => !this.processedBadges?.find((obtainedBadge) => obtainedBadge.type === badge.type));

        this.processedBadges = this.processedBadges?.concat(lockedBadges.map((badge) => {
            return {
                ...badge,
                isUnlocked: false
            }
        }));
    },
}
</script>

<template>
    <div class="badge-header">
        <h5>
            Suas conquistas
        </h5>
    </div>
    <div v-if="this.processedBadges" class="badge-container">
        <div class="badge-body" v-for="badge in this.processedBadges" :key="badge.type">
            <img :src="badge.image" :alt="badge.name" :class="`badge-icon ${badge.isUnlocked ? '' : 'greyscale'}`"
                v-tooltip.bottom="{ content: `${badge.isUnlocked ? badge.unlockedDescription : badge.lockedDescription}`, theme: 'info-tooltip', distance: 8, }" />
        </div>

        <slot></slot>
    </div>

    <div v-else>
        <p>
            Você ainda não possui nenhuma conquista.
        </p>
    </div>
</template>


<style scoped>
.badge-header {
    display: flex;
    width: 100%;
    justify-content: center;
}

.badge-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 96%;
    height: 75%;
    justify-content: space-evenly;
    gap: 2rem
}

.badge-icon {
    width: 64px;
    height: 64px;
}

.greyscale {
    filter: grayscale(100%);
    opacity: 0.5;
}
</style>