<script>
export default {
  name: "CustomSkeleton",
  props: {
    customClass: {
      type: String,
      default: "custom-skeleton",
    },
    width: {
      type: String,
      default: "100px",
    },
    height: {
      type: String,
      default: "20px",
    },
    backgroundColor: {
      type: String,
      default: "#ddd",
    },
    borderRadius: {
      type: String,
      default: "4px",
    },
    marginTop: {
      type: String,
      default: "0.5rem",
    },
  },
  computed: {
    customStyles() {
      return {
        width: this.width,
        height: this.height,
        backgroundColor: this.backgroundColor,
        borderRadius: this.borderRadius,
        marginTop: this.marginTop,
      };
    },
  },
};
</script>

<template>
  <div :class="['custom-skeleton', customClass]" :style="customStyles">
    <slot></slot>
  </div>
</template>
  
  <style scoped>
.custom-skeleton {
  animation: skeleton-pulse 1s infinite;
      background-image: linear-gradient(45deg, #e0e0e0, rgb(243 243 243), #d4d4d4);
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
  