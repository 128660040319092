<script>
export default {
  name: "Warnings",
  props: {
    warningType: {
      type: String,
      required: true,
    },

    setDescription: {
      required: true,
      default: null,
    },

    showCloseButton: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: Boolean,
      default: true,
    },
    
  },
};
</script>


<template>
  <Transition name="rotateX">
    <div
      v-if="setDescription || $slots.description"
      class="alertBox"
      :class="warningType"
    >
      <i v-if="icon" class="bx bx-error"></i>

      <slot name="description"></slot>
      {{ setDescription }}
      <span v-if="showCloseButton" @click="$emit('closeWarning')"></span>
    </div>
  </Transition>
</template>

<style scoped>
.alertBox {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: var(--white-3);
  border: 1px solid var(--dividing-line-primary);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0px 0.5rem;
  user-select: none;
  gap: 0.5rem;
}

.alertBox::before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}

.bx.bx-error {
  margin-top: 2px;
  font-size: inherit;
}

.alertBox span {
  content: "";
  background-image: url("../assets/x-icon-black.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-left: auto;
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;
}

body.dark-mode .alertBox span {
  background-image: url("../assets/x-icon-white.svg");
}

.login_page .alertBox span {
  background-image: url("../assets/x-icon-white.svg");
}

.alertBox span:hover {
  opacity: 1;
}

.alertBox.error {
  background-color: var(--alert-error-background);
  color: var(--alert-error-color);
  border: 1px solid var(--alert-error-border);
}

.alertBox.success {
  background-color: var(--alert-success-background);
  color: var(--alert-success-color);
  border: 1px solid var(--alert-success-border);
}

.alertBox.info {
  background-color: var(--blue-3);
  color: var(--blue-2);
  border: 1px solid var(--blue-4);
}

.dark-mode .alertBox.info {
  border-color: rgba(76, 130, 247, 0.25);
    background-color: rgba(76, 130, 247, 0.05);
}



.alertBox.warning {
  background-color: var(--yellow-3);
  color: var(--yellow-2);
  margin: 0.5rem 0px;
  border: 1px solid var(--yellow-4);
}

.dark-mode .alertBox.warning {
  --yellow-3: rgba(255, 186, 8, 0.05);
  --yellow-4: rgba(255, 186, 8, 0.25);
}

.alertBox.warning span {
  display: none;
}

.warningIcon {
  display: none;
}

.info .warningIcon {
  display: block;
}

@media screen and (max-width: 995px) {
  .alertBox.error::before {
    align-self: center;
    margin-top: 0px;
  }
}
</style>