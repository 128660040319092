<script>
import VueApexCharts from "vue3-apexcharts";
import moment from "moment";

export default {
  name: "Chart",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      options: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        colors: ["#23C55F", "#7AD99F", "#ADE4C4"],
        fill: {
          colors: undefined,
          opacity: 0.9,
          gradient: {
            type: "vertical",
            gradientToColors: ["#23C55F", "#7AD99F", "#ADE4C4"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0.6,
          },
        },
        grid: {
          show: true,
          borderColor: "",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        chart: {
          id: "lastSalesChart",
          height: "380px",
          minHeight: "380px",
          type: "area",
          width: "40%",
          foreColor: "var(--dark-grey-2)",
          fontFamily: "var(--inter)",
          toolbar: {
            autoSelected: "zoom",
            show: false,
          },
          stacked: true,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return this.formatPrice(value) ?? "R$ 0,0";
            },
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  },

  watch: {
    lastDaysAmount(newValue, oldValue) {
      this.reportsHome();
    },
  },

  props: {
    lastDaysAmount: {
      type: Number,
    },
    chartData: {
      type: Object,
      default: '',
    }
  },

  methods: {
    formatData(data) {
      let salesData = data.thirtyDaysSalesByDay;

      switch (this.lastDaysAmount) {
        case 7:
          salesData = salesData.slice(-7);
          break;

        case 15:
          salesData = salesData.slice(-15);
          break;

        case 30:
          salesData = salesData.slice(-30);
          break;
      }

      const formattedData = salesData.map((item) => {
        return {
          x: moment(item.date).format("DD/MM/YYYY"),
          y: item.sum
            .toFixed(2)
            .toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
        };
      });
      return formattedData;
    },
    reportsHome() {
        const formattedData = this.formatData(this.chartData);
        this.series = [
          {
            name: null,
            data: formattedData,
          },
        ];
    },
    formatPrice(valor) {
      if (valor) {
        return valor.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        });
      }
    },
  },
};
</script>
<template>
  <div id="lastSalesChart">
    <apexchart
      height="380px"
      width="100%"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>
<style scoped>
#lastSalesChart .apexcharts-yaxis-label tspan,
.apexcharts-text tspan {
  font-size: 12px;
}

.vue-apexcharts {
  min-height: 380px !important;
}

.apexcharts-tooltip.apexcharts-theme-light,
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  transition: var(--slow-main);
}

body.dark-mode .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid var(--grey);
  background: var(--grey);
}

body.dark-mode
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: var(--grey);
  border-bottom: 1px solid var(--white);
}

@media screen and (max-width: 995px) {
  .apexcharts-text tspan {
    font-size: 12px !important;
  }

  .apexcharts-yaxis-texts-g text {
    margin-top: 10px !important;
  }
}
</style>