import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueAxios from 'vue-axios';
import VueTheMask from 'vue-the-mask'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'boxicons/css/boxicons.css';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'




axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// Lista de rotas anônimas
const anonymousRoutes = [];

axios.interceptors.request.use(async (config) => {
  const isAnonymousRoute = anonymousRoutes.some(({ method, route }) =>
    config.method.toLowerCase() === method.toLowerCase() && config.url.includes(route)
  );

  if (isAnonymousRoute) {
    delete config.headers['Authorization'];
  } else {
    const accessToken = store.state.user.access_token;
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return config;
});

axios.interceptors.response.use((response) => {
  return Promise.resolve(response);
}, error => {
  if (
    error.response.status == 401
  ) {
    store.commit("removeUserData");
    localStorage.removeItem('firstName');

    localStorage.removeItem('must_reset_password');

    router.replace({
      path: "/login",
      query: { redirect: router.currentRoute.fullPath }
    });
  }
  return Promise.reject(error);
});

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

const app = createApp(App)


app.use(FloatingVue, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      $resetCss: true,
    },
  },
})

app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "bottom-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});
app.use(VueTheMask)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.config.globalProperties.$axios = axios;
app.mount('#app');
