export function animateValue(targetValue, duration, onUpdate) {
  const increment = targetValue / duration;

  let startTime = null;

  const animate = (timestamp) => {
    if (!startTime) startTime = timestamp;

    const elapsedTime = timestamp - startTime;
    const currentValue = Math.min(increment * elapsedTime, targetValue);

    onUpdate(currentValue);

    if (elapsedTime < duration) {
      requestAnimationFrame(animate); // Chama a próxima etapa da animação
    }
  };

  requestAnimationFrame(animate); // Inicia a animação
}
