<script>
export default {
  name: "CustomSkeleton",
  props: {
    gap: String,
    row: String,
  },
  computed: {
    customStyles() {
      let alignItems = "";
      switch (this.row) {
        case "left":
          alignItems = "flex-start";
          break;
        case "center":
          alignItems = "center";
          break;
        case "right":
          alignItems = "flex-end";
          break;
        default:
          break;
      }

      return {
        gap: this.gap,
        alignItems: alignItems,
      };
    },
  },
};
</script>

<template>
  <div class="skeletonContainer" :style="customStyles">
    <slot></slot>
  </div>
</template>
  
  <style scoped>
.skeletonContainer {
  display: flex;
  flex-direction: column;
}

.skeletonCell {
  animation: skeleton-pulse 1s infinite;
  background-image: linear-gradient(45deg, #e0e0e0, rgb(243 243 243), #d4d4d4);
}

@keyframes skeleton-pulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
  