<script>
export default {
  name: "CopyrightFooter",
};
</script>

<template>
  <div
    v-if="$route.name === 'Create Account' || $route.name === 'Login'"
    class="copyrightText"
  >
    <strong>Pepper ©</strong> {{ new Date().getFullYear() }}, Todos os direitos
    reservados.
  </div>
  <div
    v-else
    id="copyrightSecondary"
    class="copyrightText"
    style="margin-top: 2rem"
  >
    Pepper © {{ new Date().getFullYear() }}

    <span v-show="this.$store.state.version" class="inherit">V {{ this.$store.state.version }}</span>
    <router-link to="/termos-de-uso"
      >Termos de uso
      <span class="inherit hide-on-mobile">e Política de privacidade</span>
    </router-link>
  </div>
</template>

<style scoped>
.copyrightText {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #9397ad;
  text-align: center;
  margin: auto;
  padding: 1.5rem 4rem 3rem;
  user-select: none;
  max-width: 1200px;
}

.copyrightText a {
  font-size: inherit;
  text-decoration: none;
  color: inherit;
  position: relative;
}

.copyrightText a::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #9397ad;
  height: 1px;
  width: 0%;
  transition: var(--slow-main);
}

.copyrightText a:hover::after {
  width: 100%;
}

.copyrightText strong {
  font-size: inherit;
  font-weight: 800;
}

.createAccount .copyrightText strong {
  margin-right: 10px;
}

.login_page .copyrightText,
.login_page .copyrightText strong {
  text-align: left;
  justify-content: flex-start;
}

.hide-on-mobile {
    display: inline;
  }

@media screen and (max-width: 995px) {
  .copyrightText {
    font-size: 0.75rem;
    padding: 1.5rem 1rem 3rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #copyrightSecondary.copyrightText {
    gap: 0.5rem;
    flex-direction: row;
    white-space: nowrap;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .hide-on-mobile {
    display: none;
  }
}
</style>