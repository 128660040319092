function addChatbox() {
  const script = document.createElement('script');
  script.id = 'ze-snippet';
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=0a15ba8b-548e-4a79-8656-0fbc71e7463e';
  document.body.appendChild(script);
}

function removeChatbox() {
  const zeSnippet = document.getElementById('ze-snippet');
  if (zeSnippet) {
    zeSnippet.parentNode.removeChild(zeSnippet);
    window.location.reload(); // Recarrega a página
  }
}



export { addChatbox, removeChatbox };
