<script>
import LoaderPage from './components/LoaderPage.vue';


export default {
    components: { LoaderPage },
    data() {
        return {
            app_name: "Pepper",
            loading: false,
        };
    },

    beforeCreate() {
        this.$router.beforeEach((to, from, next) => {
            this.loading = true;
            next();
        });

        this.$router.afterEach(() => {
          setTimeout(() => {
                this.loading = false;
            }, 1000);
        });
    }
};
</script>
<template>
  <div>
    <div>
      <router-view></router-view>

      <Transition>
        <LoaderPage v-if="loading"/>
      </Transition>
    </div>
  </div>
</template>





