import { createRouter, createWebHistory } from 'vue-router'
import DefaultPage from '../pages/DefaultPage'
import Home from '../pages/Home'


const routes = [
  {
    path: '/404',
    name: '404',
    component: () => import('../pages/NotFoundPage.vue'),
    meta: {
      title: 'Página não encontrada',
      description: 'Pepper - Página não encontrada. Por favor, volte para a página inicial.'
    }
  },
  {
    path: '/',
    name: 'home-page',
    redirect: to => {
      window.location.href = 'https://lp.pepper.com.br';
    }
  },
  {
    path: '/termos-de-uso',
    name: 'terms-of-use',
    component: () => import('../pages/TermsOfUse.vue')
  },
  {
    path: '/termos-de-uso.html',
    redirect: '/termos-de-uso',
  },
  {
    path: '/home.html',
    redirect: '/home',
  },
  {
    path: '/',
    redirect: '/home',
    name: 'DefaultPage',
    component: DefaultPage,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
          title: 'Home',
        },
      },
      {
        path: '/vendas',
        name: 'My sales',
        component: () => import('../pages/MySales.vue'),
        meta: {
          title: 'Minhas vendas',
        },
      },
      {
        path: 'vendas.html',
        redirect: '/vendas',
      },

      {
        path: '/relatorios/relatorio-geral',
        name: 'report-geral',
        component: () => import('../pages/reports-page/ReportGeral.vue'),
        meta: {
          title: 'Relatórios',
        }
      },
      {
        path: 'relatorios.html',
        redirect: '/relatorios/relatorio-geral',
      },
      {
        path: '/relatorios/relatorio-detalhado',
        name: 'detailed-report',
        component: () => import('../pages/reports-page/DetailedReport.vue'),
        meta: {
          title: 'Relatórios detalhados',
        }
      },
      {
        path: '/relatorio-detalhado.html',
        redirect: '/relatorios/relatorio-detalhado',
      },
      {
        path: '/relatorios/relatorio-gerado',
        name: 'report-generated',
        component: () => import('../pages/reports-page/ReportGenerated.vue'),
        meta: {
          title: 'Relatórios gerados',
        }
      },
      {
        path: 'relatorios-gerados.html',
        redirect: '/relatorios/relatorio-gerado'
      },
      {
        path: '/relatorios/relatorio-funil',
        name: 'report-funnel',
        component: () => import('../pages/reports-page/ReportFunnel.vue'),
        meta: {
          title: 'Relatório do funil de vendas',
        }
      },
      {
        path: '/relatorio-funil.html',
        redirect: '/relatorios/relatorio-funil'
      },

      {
        path: '/integracoes/gerenciar-integracoes',
        name: 'Integrations',
        component: () => import('../pages/integrations/Integrations.vue'),
        meta: {
          title: 'Integrações',
        },
        children: [
          {
            path: '/integracoes/',
            redirect: '/integracoes/gerenciar-integracoes'
          }
        ]
      },
      {
        path: '/integracoes.html',
        redirect: '/integracoes/gerenciar-integracoes',
      },
      {
        path: '/integracoes/historico',
        name: 'integrations-history',
        component: () => import('../pages/integrations/IntegrationsHistory.vue'),
        meta: {
          title: 'Integração integrations',
        }
      },
      {
        path: '/integracoes-historico.html',
        redirect: '/integracoes/historico',
      },
      {
        path: '/integracoes/pepper-members',
        name: 'pepper-members',
        component: () => import('../pages/integrations/integrations-page/PepperMembers.vue'),
        meta: {
          title: 'Integração Pepper Members',
        }
      },
      {
        path: '/integracao-peppermembers.html',
        redirect: '/integracoes/pepper-members',
      },
      {
        path: '/integracoes/cademi',
        name: 'cademi',
        component: () => import('../pages/integrations/integrations-page/Cademi.vue'),
        meta: {
          title: 'Integração Cademi',
        }
      },
      {
        path: '/integracao-cademi.html',
        redirect: '/integracoes/cademi',
      },
      {
        path: '/integracoes/memberkit',
        name: 'memberkit',
        component: () => import('../pages/integrations/integrations-page/Memberkit.vue'),
        meta: {
          title: 'Integração Memberkit',
        }
      },
      {
        path: '/integracao-memberkit.html',
        redirect: '/integracoes/memberkit',
      },
      {
        path: '/integracoes/facebook',
        name: 'facebook',
        component: () => import('../pages/integrations/integrations-page/Facebook.vue'),
        meta: {
          title: 'Integração Facebook',
        }
      },
      {
        path: '/integracao-facebook.html',
        redirect: to => {
          const queryParams = window.location.search;
          window.location.href = `/integracoes/facebook${queryParams}`;
        }
      },
      {
        path: '/integracoes/kwai',
        name: 'kwai',
        component: () => import('../pages/integrations/integrations-page/Kwai.vue'),
        meta: {
          title: 'Integração Kwai',
        }
      },
      {
        path: '/integracao-kwai.html',
        redirect: to => {
          const queryParams = window.location.search;
          window.location.href = `/integracoes/kwai${queryParams}`;
        }
      },
      {
        path: '/integracoes/googleAnalytics',
        name: 'google-analytics',
        component: () => import('../pages/integrations/integrations-page/GoogleAnalytics.vue'),
        meta: {
          title: 'Integração Google Analytics',
        }
      },
      {
        path: '/integracao-analytics.html',
        redirect: to => {
          const queryParams = window.location.search;
          window.location.href = `/integracoes/googleAnalytics${queryParams}`;
        }
      },
      {
        path: '/integracoes/tiktok',
        name: 'tiktok',
        component: () => import('../pages/integrations/integrations-page/Tiktok.vue'),
        meta: {
          title: 'Integração Tiktok',
        }
      },
      {
        path: '/integracao-tiktok.html',
        redirect: to => {
          const queryParams = window.location.search;
          window.location.href = `/integracoes/tiktok${queryParams}`;
        }
      },
      {
        path: '/integracoes/webhook',
        name: 'webhook',
        component: () => import('../pages/integrations/integrations-page/Webhook.vue'),
        meta: {
          title: 'Integração Webhook',
        }
      },
      {
        path: 'integracao-webhook.html',
        redirect: '/integracoes/webhook',
      },
      {
        path: '/integracoes/voxuy',
        name: 'voxuy',
        component: () => import('../pages/integrations/integrations-page/Voxuy.vue'),
        meta: {
          title: 'Integração Voxuy',
        }
      },
      {
        path: '/integracao-voxuy.html',
        redirect: '/integracoes/voxuy',
      },
      {
        path: '/integracoes/spedy',
        name: 'spedy',
        component: () => import('../pages/integrations/integrations-page/Spedy.vue'),
        meta: {
          title: 'Integração Spedy',
        }
      },
      {
        path: '/integracao-spedy.html',
        redirect: '/integracoes/spedy',
      },
      {
        path: '/integracoes/notazz',
        name: 'notazz',
        component: () => import('../pages/integrations/integrations-page/Notazz.vue'),
        meta: {
          title: 'Integração Notazz',
        }
      },
      {
        path: '/integracao-notazz.html',
        redirect: '/integracoes/notazz',
      },
      {
        path: '/integracoes/hotzapp',
        name: 'hotzapp',
        component: () => import('../pages/integrations/integrations-page/Hotzapp.vue'),
        meta: {
          title: 'Integração Hotzapp',
        }
      },
      {
        path: 'integracao-hotzapp.html',
        redirect: '/integracoes/hotzapp',
      },
      {
        path: '/integracoes/googleads',
        name: 'Google-Ads',
        component: () => import('../pages/integrations/integrations-page/GoogleAds.vue'),
        meta: {
          title: 'Integração GoogleLeads',
        }
      },
      {
        path: '/integracao-googleads.html',
        redirect: to => {
          const queryParams = window.location.search;
          window.location.href = `/integracoes/googleads${queryParams}`;
        }
      },
      {
        path: '/integracoes/smartnotifications',
        name: 'smartnotifications',
        component: () => import('../pages/integrations/integrations-page/Smartnotifications.vue'),
        meta: {
          title: 'Integração SmartNotifications',
        }
      },
      {
        path: '/integracao-smartnotifications.html',
        redirect: '/integracoes/smartnotifications',
      },
      {
        path: '/integracoes/pepperzap',
        name: 'pepperzap',
        component: () => import('../pages/integrations/integrations-page/Pepperzap.vue'),
        meta: {
          title: 'Integração Pepperzap',
        }
      },
      {
        path: '/integracao-pepperzap.html',
        redirect: '/integracoes/pepperzap',
      },
      {
        path: '/integracoes/configuracao',
        name: 'configure integration',
        component: () => import('../pages/ConfigureIntegration.vue'),
      },
      {
        path: '/meu-perfil',
        name: 'my-profile',
        component: () => import('../pages/MyProfile.vue'),
        meta: {
          title: 'Meu perfil',
        }
      },
      {
        path: '/meu-perfil.html',
        redirect: 'meu-perfil'
      },
      {
        path: '/financeiro',
        name: 'Balance and withdraw',
        component: () => import('../pages/BalanceAndWithdrawal.vue'),
        meta: {
          title: 'Saldo e saque',
        }
      },
      {
        path: '/financeiro.html',
        redirect: '/financeiro'
      },
      {
        path: '/colaboradores',
        name: 'Collaborators',
        component: () => import('../pages/Collaborators.vue'),
        meta: {
          title: 'Colaboradores',
        }
      },
      {
        path: '/colaboradores.html',
        redirect: '/colaboradores',
      },
      {
        path: '/mercado-de-afiliacao',
        name: 'affiliate-market',
        component: () => import('../pages/affiliations/AffiliateMarket.vue'),
        meta: {
          title: 'Mercado de afiliação',
        }
      },
      {
        path: '/sou-afiliado',
        name: 'sou-afiliado',
        component: () => import('../pages/affiliations/ImAffiliate.vue'),
        meta: {
          title: 'Sou afiliado',
        }
      },
    ]
  },
  {
    path: '/checkout-builder',
    name: 'checkout-builder',
    component: () => import('../pages/CheckoutBuilder.vue'),
    meta: {
      title: 'Checkout Builder',
    }
  },
  {
    path: '/checkout-builder.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `/checkout-builder${queryParams}`;
    }
  },
  {
    path: '/solicitar-reembolso',
    name: 'Refund page',
    beforeEnter: (to, from, next) => {
      window.location.href = 'https://conteudos-pepper.pepper.com.br/solicitar-reembolso';
    },
    component: () => import('../pages/Refund.vue'),
    meta: {
      title: 'Solicitar reembolso',
    }
  },
  {
    path: '/solicitar-reembolso.html',
    redirect: '/solicitar-reembolso'
  },
  {
    path: '/minhas-compras',
    name: 'my-shopping',
    component: () => import('../pages/MyShopping.vue'),
    meta: {
      title: 'Minhas compras',
    }
  },
  {
    path: '/acessar-compras.html',
    redirect: '/minhas-compras'
  },
  {
    path: '/minhas-compras.html',
    redirect: '/minhas-compras'
  },
  {
    path: '/redefinicao-de-senha',
    name: 'Reset password',
    component: () => import('../pages/ResetPassword.vue'),
    meta: {
      title: 'Redefinição de senha',
    }
  },
  {
    path: '/esqueci-minha-senha.html',
    redirect: to => {
      const queryParams = window.location.search;
      const params = new URLSearchParams(to.fullPath.split('?')[1]);
      const code = params.get('code');
      window.location.href = `/redefinicao-de-senha${code ? `${queryParams}` : ''}`;
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login.vue'),
    meta: {
      title: 'Login',
    }
  },
  {
    path: '/login.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `/login${queryParams}`;
    }
  },
  {
    path: '/criar-conta',
    name: 'Create Account',
    component: () => import('../pages/CreateAccount.vue'),
    meta: {
      title: 'Criar conta',
    }
  },
  {
    path: '/criar-conta.html',
    redirect: to => {
      const queryParams = window.location.search;
      return `/criar-conta${queryParams}`;
    }
  },
  {
    path: '/checkout/index.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `https://checkout.pepper.com.br${queryParams}`;
    }
  },
  {
    path: '/checkout/checkout.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `https://checkout.pepper.com.br${queryParams}`;
    }
  },
  {
    path: '/checkout/obrigado-billet.html',
    redirect: to => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      window.location.href = `https://checkout.pepper.com.br/obrigado/${id}`;
    }
  },
  {
    path: '/checkout/obrigado-cartao.html',
    redirect: to => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      window.location.href = `https://checkout.pepper.com.br/obrigado/${id}`;
    }
  },
  {
    path: '/checkout/obrigado-paid.html',
    redirect: to => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      window.location.href = `https://checkout.pepper.com.br/obrigado/${id}`;
    }
  },
  {
    path: '/checkout/obrigado-pix.html',
    redirect: to => {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id');
      window.location.href = `https://checkout.pepper.com.br/obrigado/${id}`;
    }
  },
  {
    path: '/temporary-access/:temporaryAccessId',
    name: 'Temporary Access',
    component: () => import('../pages/TemporaryAccess.vue'),
    props: true
  },
  {
    path: '/confirmar-coproducao',
    name: 'coproducer confirmation',
    component: () => import('../pages/coproducers-page/CoproducerConfirmation.vue'),
    meta: {
      title: 'Confirmar coprodução',
    }
  },
  {
    path: '/criar-coproducao.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `/confirmar-coproducao${queryParams}`;
    }
  },
  {
    path: '/aceitar-coproducao',
    name: 'coproducer accept',
    component: () => import('../pages/coproducers-page/AcceptCoproduction.vue'),
    meta: {
      title: 'Aceitar coprodução',
    }
  },
  {
    path: '/aceitar-coproducao.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `/aceitar-coproducao${queryParams}`;
    }
  },
  {
    path: '/encerrar-coproducao',
    name: 'coproducer resigne',
    component: () => import('../pages/coproducers-page/EndCoproduction.vue'),
    meta: {
      title: 'Encerrar coprodução',
    }
  },
  {
    path: '/encerrar-coproducao.html',
    redirect: to => {
      const queryParams = window.location.search;
      window.location.href = `/encerrar-coproducao${queryParams}`;
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const defaultTitle = 'Pepper';
  document.title = to.meta.title ? `${defaultTitle} - ${to.meta.title}` : defaultTitle;
  if (to.matched.length === 0) {
    next('/404');
  } else {
    next();
  }
});




export default router
