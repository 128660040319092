<script>
export default {
  name: "New",
};
</script>

<template>
  <div class="new-icon">Novo</div>
</template>

<style scoped>
.new-icon {
  border-radius: 14px;
  padding: 5px 5px;
  background-color: var(--primary-color-main);
  border: 1px solid var(--black);
  color: #fff;
  font-size: 8px;
  font-weight: 800;
  line-height: 1em;
  box-shadow: var(--secondary-main-shadow);
    animation: pulse 2.2s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.9;
  }

  50% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(0.8);
    opacity: 0.9;
  }
}
</style>