<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    btn: {
      type: Object,
      default: () => ({
        close: true,
      }),
    },
  },
};
</script>


<template>
  <div class="modalContainer" :style="[ {maxWidth: large ?? '500'}, {paddingBottom: $slots.footer ? '1.5rem' : '0rem'} ]">
    <div v-if="$slots.header" class="modal-header">
      <slot name="header"></slot>
      <span
        v-if="btn.close"
        @click="$emit('close', false)"
        class="closeButton"
      ></span>
    </div>
    <div v-if="$slots.body" class="modal-body">
      <slot name="body"></slot>
    </div>

    <div v-if="$slots.footer" class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style scoped>
.modalContainer {
  overflow-y: auto;
}
</style>