<script>
    export default {
        name: "Loader page",
    }
</script>

<template>
    <div class="loaderPage">
        <span class="preloader"></span>
    </div>
</template>

<style scoped>
    .loaderPage {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--white);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 19;
        left: 0px;
    }

    .not-loader .loaderPage {
        display: none;
    }

    .preloader {
        background-image: url('../assets/pepper-images/pepper-logo-min.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 55px;
        width: 55px;
        display: block;
        animation: pulse 2.2s linear infinite;
    }
</style>