

<script>
export default {
  props: {
    light: Boolean,
  }
}
</script>

<template>
  <span class="loaderSpinner" :class='{light: light}'></span>
</template>

<style scoped>
.loaderSpinner {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loaderSpinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid var(--primary-color-main-dark);
  animation: prixClipFix 2s linear infinite;
}

.light.loaderSpinner::before {
  border: 2px solid #fff;
}

.btn.success .loaderSpinner::before {
  border-color: #fff;
}

.primary-button .loaderSpinner::before {
  border: 2px solid var(--white);
}

.dark-mode .buttonLogin .loaderSpinner::before,
.dark-mode .primary-button .loaderSpinner::before {
  border: 2px solid var(--black);
}
</style>