<script>
    export default {
        name: "Dropdown",

        data() {
            return {
                dropdownOpen: false,
                vmodel: '',
            }
        },

        watch: {
            vmodel: {
                handler(newValue) {
                    this.$emit('update:modelValue', newValue);
                },
                immediate: true,
            },
        },

        props: {
            listItems: {
                type: Array,
                required: true
            },

            dropdownId: {
              type: String,
              required: true
            },

            setLabel: {
              type: String,
            },

            setPlaceholder: {
              type: String,
            }
        },

        methods: {
          statusList(status) {
            document.getElementById(this.dropdownId).value = status;
            this.vmodel = status;
          }
        },
    }
</script>

<template>
    <div class="dropdown-content">
      <label class="labelTitle" v-if="setLabel" :for="this.dropdownId">{{ setLabel }}</label>
        <div @click="this.dropdownOpen = !this.dropdownOpen" :class="[this.dropdownOpen == true ? 'open' : '']"  class="dropdownButton">

            <input 
              type="text" 
              @input="$emit('update:modelValue', $event.target.value)" 
              v-model="this.vmodel" :id="this.dropdownId" 
              :Placeholder="this.setPlaceholder" 
              :name="this.dropdownId" 
              readonly
            >
            <Transition>
                <ul v-if="this.dropdownOpen" @blur="!this.dropdownOpen" class="dropdown-menu">
                    <li 
                      v-for="(list, index) in listItems" 
                      :key="index" 
                      @click="statusList(list.item); $emit('item-clicked', index)"
                    >
                      {{ list.item }}
                    </li>
                </ul>
            </Transition>
        </div>
    </div>
</template>

<style scoped>
.dropdown-menu {
    max-height: 250px !important;
    overflow-y: scroll !important;
}

</style>