<script>
import HeaderMain from "../components/HeaderMain.vue";
import router from "../router";
import Dropdown from "../components/Dropdown.vue";
import Warnings from "../components/Warnings.vue";
import { useToast } from "vue-toastification";
import CopyrightFooter from "../components/CopyrightFooter.vue";
import DropdownSearch from "../components/dropdowns/DropdownSearch.vue";
import { addChatbox, removeChatbox } from "../js/chatbox";

export default {
  name: "DefaultPage",
  components: {
    HeaderMain,
    Dropdown,
    Warnings,
    CopyrightFooter,
    DropdownSearch,
  },

  data() {
    return {
      setOptionsLegalNature: [
        { indexItem: 0, item: "CPF" },
        { indexItem: 1, item: "CNPJ" },
        { indexItem: 2, item: "MEI" },
      ],
      isAnimating: false,
      additionalInfo: false,
      whatsNumber: "",
      legalNature: "",
      documentNumber: "",
      errorBox: null,
    };
  },

  computed: {
    documentType() {
      return this.documentNumber.length > 14 ? "cnpj" : "cpf";
    },
    documentMask() {
      return this.documentType === "cnpj"
        ? "##.###.###/####-##"
        : "###.###.###-####";
    },
  },

  methods: {
    loginDataChecks() {
      this.additionalInfo = this.$store.state.user.needsAdditionalInfo;

      if (this.$store.state.user.access_token == null) {
        router.push("/login");
      }
    },

    sendAdditionalInfo() {
      if (!this.checkFields()) return;
      this.loginDataChecks();
      $(".submit-button .loaderSpinner").show(200);

      const data = {
        phone: this.whatsNumber,
        documentType: this.legalNature,
        document: this.documentNumber,
      };

      this.axios
        .post("/api/Users/additional-info", data)
        .then((res) => {
          this.$store.commit("setAdditionalInfo");
          useToast().success("Adicionada informações com sucesso");
          this.additionalInfo = data.document === "" ? false : true;
          this.loginDataChecks();
          document.documentElement.style.overflow = "auto";
        })
        .catch((err) => {
          console.log(err);
          switch (err.response.data) {
            case "Document already belongs to other user":
              this.errorBox =
                "Já existe uma conta cadastrada com este documento";
              useToast().error(
                "Já existe uma conta cadastrada com este documento"
              );
              break;
            case "User document already set":
              this.errorBox =
                "Já existe uma conta cadastrada com este documento";
              useToast().error(
                "Já existe uma conta cadastrada com este documento"
              );

              break;
            case "User CPF is invalid":
              this.errorBox = "Documento invalido";
              useToast().error("Documento invalido");

              break;
            case "User CNPJ/MEI is invalid":
              this.errorBox = "Documento invalido";
              useToast().error("Documento invalido");

              break;
          }
        })
        .finally(() => {
          $(".submit-button .loaderSpinner").hide(200);
        });
    },

    checkFields() {
      if (!this.whatsNumber || this.legalNature === "" || !this.documentNumber) {
        this.errorBox = "Preencha todos os campos";
        return false;
      }
      return true;
    },

    formatDocument() {
      let documentValue = this.documentNumber.replace(/\D/g, "");

      if (documentValue.length <= 11) {
        documentValue = documentValue.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          "$1.$2.$3-$4"
        );
      } else {
        documentValue = documentValue.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
      }

      let phone = this.whatsNumber.replace(/\D/g, "");
      if (phone.length === 11) {
        phone = phone.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      } else {
        phone = phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      }
      this.whatsNumber = phone;
      this.documentNumber = documentValue;
    },
    setLegalNature(data) {
      this.legalNature = data.indexItem;
    },
    renderChatbox() {
      const totalAmount = this.$store.state.balanceData.totalAmount;
      if (totalAmount > 10000 && this.$store.state.user.access_token) {
        addChatbox();
      }
    },
    destroyChatbox() {
      removeChatbox();
    },
  },
  mounted() {
    this.renderChatbox();
  },
  created() {
    this.loginDataChecks();
    if (this.additionalInfo == true) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }

    if (!this.$store.state.user.access_token) {
      this.$store.commit("removeUserData");

      this.$router.push("/login");
    }

    if (this.$store.state.user.isRecipientRefused == null || this.$store.state.user.isRecipientRefused) {
      this.$axios.get("/api/Users/is-refused").then((req) => {
        this.$store.commit("setRecipientRefused", req.data.isRecipientRefused);
        return this.$router.push("/home");
      });
    }

  },
};
</script>

<template>
  <div>
    <HeaderMain @logout="destroyChatbox" />
    <main>
      <Transition name="fadeGrowingCenterFixed">
        <form
          v-if="additionalInfo"
          class="modal-content-secondary"
          :class="{ growingAnimation: isAnimating }"
          @animationend="this.isAnimating = false"
          @submit="sendAdditionalInfo"
          v-on:submit.prevent="onSubmit"
        >
          <div class="modal-header">
            <h2 class="welcome-icon">Estamos quase...</h2>
            <p>
              Precisamos só de mais alguns dados para você poder começar a
              vender!
            </p>
          </div>
          <div class="modal-body">
            <div class="input_container">
              <label class="labelTitle" for="whatsNumber"
                >Celular com WhatsApp</label
              >

              <div class="inputContainer secondary">
                <span class="box-content whatsapp-icon"></span>
                <input
                  v-mask="['(##) #####-####']"
                  type="text"
                  inputmode="tel"
                  v-model="whatsNumber"
                  id="whatsNumber"
                />
              </div>
            </div>

            <div class="content-double-input">
              <dropdown-search
                :listItems="setOptionsLegalNature"
                dropdownId="legalNature"
                set-label="Natureza jurídica"
                setPlaceholder="Escolha a opção"
                @item-clicked="setLegalNature"
                :item-template="(item) => `${item.item}`"
              />

              <div class="input_container">
                <label class="labelTitle" for="documentsData"
                  >Digite o seu CPF ou CNPJ</label
                >
                <input
                  type="text"
                  inputmode="tel"
                  name="documentsData"
                  id="documentsData"
                  v-model="this.documentNumber"
                  v-mask="documentMask"
                />
              </div>
            </div>
            <p class="warningText">
              Preencha com atenção, pois depois não será possível alterar.
            </p>

            <Warnings
              warningType="error"
              :setDescription="this.errorBox"
              @close-warning="this.errorBox = null"
            />
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn primary-button submit-button">
              <span class="loaderSpinner" style="display: none"></span>
              Próximo
            </button>
          </div>
        </form>
      </Transition>

      <Transition>
        <div
          v-if="additionalInfo"
          @click="this.isAnimating = true"
          class="modal-fade"
        ></div>
      </Transition>
      <router-view></router-view>
      <CopyrightFooter />
    </main>
  </div>
</template>

<style src="@/styles/global.css" />