<script>
import SideBar from "../components/SideBar.vue";
import routes from "../router/index";

export default {
  name: "HeaderMain",
  components: {
    SideBar,
  },

  data() {
    return {
      firstName: this.$store.state.user.firstName,
    };
  },

  methods: {
    openConfigModal() {
      if (!this.showConfigModal) {
        this.showConfigModal = true;
        $(".configModal").show(200);
      } else {
        this.showConfigModal = false;
        $(".configModal").hide(200);
      }
    },

    asideOpen() {
      $("body").toggleClass("asideOpen");

      if ($("body").hasClass("asideOpen")) {
        localStorage.setItem("asideActive", true);
      } else {
        localStorage.setItem("asideActive", false);
      }
    },

    darkModeButton() {
      $("body").toggleClass("dark-mode");

      if ($("body").hasClass("dark-mode")) {
        localStorage.setItem("darkMode", true);
      } else {
        localStorage.setItem("darkMode", false);
      }
    },

    darkMode() {
      const darkActive = localStorage.getItem("darkMode");
        document.body.classList.remove("dark-mode");
      if (darkActive == "true") {
        document.body.classList.add("dark-mode");
      } else if (darkActive == "false") {
        document.body.classList.remove("dark-mode");
      }

      const body = document.body;
      const resize = () => {
        if (window.innerWidth < 1051) {
          body.classList.remove("asideOpen");
        }
      };

      window.addEventListener("resize", resize);
      resize();
    },

    logout() {
      const chatBox = document.querySelector("#crisp-chatbox");
      if (chatBox) {
        chatBox.style.setProperty("display", "none", "important");
      }

      this.$store.commit("removeUserData");
      routes.push("/login");
    },
  },
  created() {
    this.darkMode();
  },
};
</script>

<template>
  <div>
    <SideBar @logout="logout" />
    <header>
      <div v-on:click="asideOpen" class="hamburguerButton">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <router-link to="/" class="logo">
        <div class="primary-logo-icon"></div>
      </router-link>

      <div class="wrapper-content">
        <div class="switchButtonDarkMode">
          <input type="checkbox" id="darkmode" />
          <label @click="darkModeButton" for="darkmode"></label>
        </div>

        <p style="user-select: none">Olá, {{ firstName }}!</p>

        <div v-on:click="openConfigModal" class="containerArrowIcon">
          <div class="profileIcon">
            <img
              v-if="this.$store.state.user.publicImageLink != null"
              :src="this.$store.state.user.publicImageLink"
              alt="Foto do perfil"
            />
          </div>

          <div v-show="false" class="configModal">
            <router-link to="/meu-perfil">
              <i class="bx bx-user"></i>
              Meu perfil
            </router-link>
            <router-link to="/financeiro">
              <i class="bx bx-wallet"></i>
              Saldo e saque
            </router-link>
            <a @click="logout">
              <i class="bx bx-log-out"></i>
              Desconectar
            </a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>




<style scoped>
header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  width: var(--largeheader);
  background-color: var(--white);
  padding: 0.5rem 2rem;
  border-bottom: 1px solid var(--dividing-line-primary);
  box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%),
    0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: var(--slow-main) ease;
}

header .primary-logo-icon {
  position: relative;
  left: auto;
  top: auto;
}

body.asideOpen header {
  width: var(--largewithAside);
}
header .logo {
  display: none;
  margin: auto;
}

header .wrapper-content {
  display: flex;
  align-items: center;
}

header .configModal {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 12rem;
  padding: 0.5rem 0px;
  margin: 0;
  font-size: 0.875rem;
  color: #585c7b;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%),
    0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
  position: absolute;
  top: 40px;
  left: -130px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

body.dark-mode header .configModal {
  border-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.85);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.03),
      rgba(255, 255, 255, 0.03)
    ),
    #131022;
}

.configModal a {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  clear: both;
  font-weight: 500;
  color: #3e4265;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  transition: var(--slow-min) ease-in-out;
  font-family: var(--bs-body-font-family);
  padding: 0.375rem 1rem;
}

body.dark-mode .configModal a {
  color: rgba(255, 255, 255, 0.85);
}

.configModal a:hover {
  color: #6366f1;
}
.configModal a.config {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 5px 15px 0px;
}

body.dark-mode .configModal a:hover {
  color: #6366f1;
  background-color: #27243d;
}
.configModal a.leave {
  position: relative;
  display: flex;
  align-items: center;
}

.configModal a.config::before,
.configModal a.leave::before {
  content: "";
  background-image: url("../assets/icon-configuracoes.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 14px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}

.configModal a.leave::before {
  background-image: url("../assets/noun-log-out.svg");
  width: 13px;
  height: 20px;
  background-position: 0px 3px;
}
.configModal a.config:hover::before {
  background-image: url("../assets/icon-configuracoes-blue.svg");
}

.configModal a.leave:hover::before {
  background-image: url("../assets/noun-log-out-blue.svg");
}

.switchButtonDarkMode {
  position: relative;
  margin-right: 64px;
}
.switchButtonDarkMode::after,
.switchButtonDarkMode::before {
  content: "";
  background-image: url("../assets/sun.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: 3px;
}

.switchButtonDarkMode::after {
  background-image: url("../assets/moon.svg");
  left: auto;
  right: -30px;
  width: 18px;
  height: 18px;
}

.switchButtonDarkMode input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0px;
  position: absolute;
}

.switchButtonDarkMode label {
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 25px;
  background-color: var(--secondary-color);
  display: block;
  border-radius: 100px;
  position: relative;
  box-shadow: var(--secondary-shadow);
}

.switchButtonDarkMode label:after {
  content: "";
  position: absolute;
  left: 1px;
  top: 1px;
  width: 25px;
  height: 23px;
  transition: var(--slow-main);
  background-image: url("../assets/circle-white.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

body.dark-mode .switchButtonDarkMode input + label:after {
  background-image: url("../assets/meia-lua.svg");
  left: calc(100% - 2px);
  transform: translateX(-100%);
  width: 19px;
}

header .wrapper-content p {
  font-weight: 700;
  margin-right: 1rem;
}

.containerArrowIcon {
  position: relative;
  width: 60px;
  cursor: pointer;
}
.containerArrowIcon::after {
  content: "";
  background-image: url("../assets/keyboard-arrow-down_119013.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 13px;
  height: 8px;
  position: absolute;
  top: 14px;
  right: 0px;
  cursor: pointer;
}

header .wrapper-content .profileIcon {
  width: 36px;
  height: 36px;
  overflow: hidden;
  background-color: #555;
  border-radius: 100%;
  background-image: url("../assets/blank-profile-picture.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

header .wrapper-content .profileIcon img {
  object-fit: cover;
  object-position: center;
  object-position: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 995px) {
  header {
    --largewithAside: 100vw;
  }

  header .logo {
    max-width: 35%;
    display: block;
    padding: 0px 10px;
  }
  header .logo img {
    max-width: 200px;
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

@media screen and (max-width: 745px) {
  header .wrapper-content p,
  .containerArrowIcon {
    display: none;
  }

  .switchButtonDarkMode {
    margin-right: 30px;
  }
  header .logo {
    max-width: 45%;
  }
}

@media screen and (max-width: 495px) {
  .switchButtonDarkMode::after,
  .switchButtonDarkMode::before {
    display: none;
  }

  .switchButtonDarkMode {
    margin: 0px;
  }
}
</style>