const badgeTypes = [
  {
    type: "Pioneer",
    name: "Pioneiro",
    unlockedDescription: "Você é um pioneiro, um dos primeiros a se juntar à comunidade!",
    lockedDescription: "Conquista atribuída aos 15.000 primeiros usuários a se juntarem à comunidade.",
    image: "producerBadges/pioneer.svg",
  },
  {
    type: "FirstSale",
    name: "Primeira venda",
    unlockedDescription: "Você realizou a sua primeira venda e já está vendendo com mais liberdade!",
    lockedDescription: "Realize ao menos uma venda para receber esta conquista.",
    image: "producerBadges/firstSale.svg",
  },
  {
    type: "TenThousandInSales",
    name: "R$ 10.000,00 mil em vendas",
    unlockedDescription: "Você vendeu mais de R$ 10.000,00 em infoprodutos!",
    lockedDescription: "Você deve vender ao menos R$ 10.000,00 em comissoes para receber esta conquista.",
    image: "producerBadges/tenThousandInSales.svg",
  },
  {
    type: "OneHundredThousandInSales",
    name: "R$ 100.000,00 em vendas",
    unlockedDescription: "Você vendeu mais de R$ 100.000,00 em infoprodutos! Esta conquista consolida seu lugar como um produtor de destaque!",
    lockedDescription: "Você deve vender ao menos R$ 100.000,00 em comissoes para receber esta conquista.",
    image: "producerBadges/oneHundredThousandInSales.svg",
  },
  {
    type: "FiveHundredThousandInSales",
    name: "R$ 500.000,00 em vendas",
    unlockedDescription: "Você vendeu mais de R$ 500.000,00 em infoprodutos! Você se tornou um especialista em seu nicho!",
    lockedDescription: "Você deve vender ao menos R$ 500.000,00 em comissoes para receber esta conquista.",
    image: "producerBadges/fiveHundredThousandInSales.svg",
  },
  {
    type: "OneMillionInSales",
    name: "R$ 1.000.000,00 em vendas",
    unlockedDescription: "Você vendeu mais de R$ 1.000.000,00 em infoprodutos e ninguém conhece seu público como você!",
    lockedDescription: "Você deve vender ao menos R$ 1.000.000,00 em comissoes para receber esta conquista.",
    image: "producerBadges/oneMillionInSales.svg",
  }
];

export { badgeTypes };
