import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
    plugins: [createPersistedState()],
    state: {
        user: {
            firstName: '',
            access_token: '',
            publicImageLink: null,
            needsAdditionalInfo: null,
            email: '',
            producerId: null,
            role: null,
            isEmployee: null,
            employeePrivilleges: null,
            isRecipientRefused: null,
        },
        balanceData: {
            totalAmount: null,
        },
        pepperUtms: {
            utmCampaign: null,
            utmSource: null,
            utmMedium: null,
            utmContent: null,
            utmTerm: null,
        },
        produtsData: {},
        version: null,
    },
    getters: {
        PRODUCT_CATEGORY: () => (categoryId) => {
            const PRODUCT_CATEGORY_MAP = {
                0: "empreendedorismo",
                1: "saúde e esportes",
                2: "design",
                3: "educação",
                4: "finanças e investimentos",
                5: "espiritualidade",
                6: "moda e beleza",
                7: "gastronomia",
                8: "relacionamentos",
                9: "estilo de vida",
                10: "direito",
                11: "outros",
            };

            return PRODUCT_CATEGORY_MAP[categoryId];
        },
        PRODUCT_DELIVERY: () => (deliveyId) => {
            const PRODUCT_DELIVERY_MAP = {
                0: "Área de membros Pepper",
                1: "Área de membros externa",
            };

            return PRODUCT_DELIVERY_MAP[deliveyId];
        },

        PRODUCT_BLOCK_REASON: () => (blockid) => {

            const PRODUCT_BLOCK_REASON_MAP = {
                0: "Produto físico",
                1: "Conteúdo erótico",
                2: "Atividade violenta ou ilícita",
                3: "Conteúdo de jogo",
                4: "Produto de streaming de TV",
                5: "Conteúdo não claro",
                6: "Reivindicação de plágio",
                7: "Produto não entregue",
                8: "Chargeback ou reembolso alto",
                9: "Comportamento estranho",
                10: "Desacordo com os termos",
            };

            return PRODUCT_BLOCK_REASON_MAP[blockid];
        }
    },
    mutations: {
        setUserData(state, userData) {
            state.user.firstName = userData.firstName;
            state.user.access_token = userData.access_token;
            state.user.publicImageLink = userData.publicImageLink;
            state.user.needsAdditionalInfo = userData.needsAdditionalInfo;
            state.user.producerId = userData.producerId;
            state.user.role = userData.role;
            state.user.isEmployee = userData.isEmployee;
            state.user.employeePrivilleges = userData.employeePrivilleges;
            state.user.email = userData.email;
            state.user.isRecipientRefused = userData.isRecipientRefused;
        },
        setUtms(state, utms) {
            state.pepperUtms.utmCampaign = utms.utmCampaign;
            state.pepperUtms.utmSource = utms.utmSource;
            state.pepperUtms.utmMedium = utms.utmMedium;
            state.pepperUtms.utmContent = utms.utmContent;
            state.pepperUtms.utmTerm = utms.utmTerm;
        },

        setRecipientRefused(state, isRefused) {
            state.user.isRecipientRefused = isRefused;
        },

        setTotalAmount(state, balanceData) {
            state.balanceData.totalAmount = balanceData;
        },

        setProducts(state, produtsData) {
            state.produtsData = produtsData;
        },

        setAdditionalInfo(state) {
            state.user.needsAdditionalInfo = false;
        },

        setVersion(state, newVersion) {
            state.version = newVersion;
        },

        removeUserData(state) {
            state.user.access_token = null;
            state.user.firstName = null;
            state.user.publicImageLink = null;
            state.user.needsAdditionalInfo = null;
            state.user.producerId = null;
            state.user.role = null;
            state.user.isEmployee = null;
            state.user.employeePrivilleges = null;
            state.user.email = null;
            state.balanceData.totalAmount = null;
            state.produtsData = null;
        }
    },
})